function setEqualHeight() {
    $(".footer-equal-title").matchHeight();
    $(".equal-contact").matchHeight();
    $(".blog-enako").matchHeight();
    $(".news-image-wrapper,.news-content-wrapper").matchHeight();
}

$(window).on("load", function() {
    setEqualHeight();
});
$(window).on("resize", function() {
    // setEqualHeight();
    $.fn.matchHeight._update();
});

$('#main-menu-state').on("change", function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $menu.css("max-height", maxHeight + "px");
                if ($menu.outerHeight() > maxHeight) {
                    $menu.css("height", maxHeight + "px");
                }
            }
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $menu.css("max-height", "");
            $menu.css("height", "");
        });
    }
});


$(window).bind('scroll', function() {
    var current_breakpoint = getSkelSize(),
        current_breakpoint_num = getNumSkelSize(),
        scroll_limit_1 = 40,
        initial_bottom = 50,
        target_bottom = 0,
        bottom = 50;
    switch (current_breakpoint) {
        case "xxxlarge":
            scroll_limit_1 = 40;
            initial_bottom = 50;
            target_bottom = 0;
            break;
        case "xxlarge":
            scroll_limit_1 = 40;
            scroll_limit = 140;
            initial_height = 100;
            target_height = 60;
            initial_padding = 10;
            target_padding = 5;
            initial_margin_top = 0;
            target_margin_top = -40;
            break;
        case "large":
            scroll_limit_1 = 40;
            scroll_limit = 140;
            initial_height = 100;
            target_height = 60;
            initial_padding = 10;
            target_padding = 5;
            initial_margin_top = 0;
            target_margin_top = -40;
            break;
        case "medium":
            scroll_limit_1 = 40;
            scroll_limit = 140;
            initial_height = 100;
            target_height = 70;
            initial_padding = 25;
            target_padding = 10;
            initial_margin_top = 0;
            target_margin_top = -40;
            break;
        case "small":
            scroll_limit_1 = 40;
            scroll_limit = 140;
            initial_height = 100;
            target_height = 70;
            initial_padding = 25;
            target_padding = 10;
            initial_margin_top = 0;
            target_margin_top = -40;
            break;
        case "xsmall":
            scroll_limit_1 = 40;
            scroll_limit = 140;
            initial_height = 100;
            target_height = 70;
            initial_padding = 25;
            target_padding = 10;
            initial_margin_top = 0;
            target_margin_top = -40;
            break;
        default:
            scroll_limit_1 = 40;
            scroll_limit = 140;
            initial_height = 100;
            target_height = 70;
            initial_padding = 25;
            target_padding = 10;
            initial_margin_top = 0;
            target_margin_top = -40;
            break;
    }
    if (current_breakpoint_num > 3) {
        if ($(window).scrollTop() === 0) {
            $("#header-wrapper").css("margin-top", "");
            $("#header-main-background").css('bottom', +initial_bottom + '%');
            if ($("#header-main-wrapper").hasClass('fixed')) {
                $("#header-main-wrapper").removeClass('fixed');
            }
        } else if ($(window).scrollTop() > 0 && $(window).scrollTop() <= scroll_limit_1) {
            $("#header-wrapper").css("margin-top", -$(window).scrollTop() + "px");
            bottom = initial_bottom - (initial_bottom * ($(window).scrollTop() / scroll_limit_1));
            $("#header-main-background").css('bottom', +bottom + '%');
            if ($("#header-main-wrapper").hasClass('fixed')) {
                $("#header-main-wrapper").removeClass('fixed');
            }
        } else {
            $("#header-main-wrapper").addClass('fixed');
            $("#header-wrapper").css("margin-top", -scroll_limit_1 + "px");
            $("#header-main-background").css('bottom', +target_bottom + '%')
        }
    } else {
        $("#header-wrapper").css("margin-top", "");
        $("#header-main-background").css('bottom', +initial_bottom + '%')
    }

});
